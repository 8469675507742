import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProfileDescriptionLocalStorageKey } from '@app/constants/localStorageKeys';
import ActiveTextButton from '@shared/ui/buttons/ActiveTextButton';
import useTextTranslation from '@shared/lib/hooks/text-translation/useTextTranslation';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { Body, BodySemiBold } from '@components/styled/Typography';
import { IsDefined } from '@utils/js-ts';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import ViewMoreToggle from './ViewMoreToggle';
import { DescriptionShortMaxLength } from './constants';
const Container = styled(ContainerColumn, { shouldForwardProp: propName => propName !== 'isLoading' })(props => ({
    width: 'auto',
    height: 'auto',
    color: props.theme.palette.text.secondary,
    maxWidth: '410px',
    gap: props.isLoading ? props.theme.spacing_sizes.xs : props.theme.spacing_sizes.m,
}));
const DescriptionActions = styled(ContainerRow)(props => ({
    height: 'auto',
    alignItems: 'center',
    lineHeight: `${Math.round(props.theme.spacing_sizes.xm * 1.2)}px`,
}));
const Divider = styled.div(props => ({
    width: '1px',
    height: '15px',
    backgroundColor: `${props.theme.palette.primary.main}`,
    margin: `0 ${props.theme.spacing_sizes.xm}px`,
}));
const DescriptionText = styled(Body)(() => ({
    wordBreak: 'break-word',
    lineHeight: '22px',
}));
const isMaxLengthExceedsLimit = (text) => text.length > DescriptionShortMaxLength;
const ProfileDescription = ({ profileId, text = '', isLoading = false, }) => {
    const { t } = useTranslation();
    const [showTranslation, setShowTranslation] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const { canTranslate, translate, textTranslation, } = useTextTranslation(text, getProfileDescriptionLocalStorageKey(profileId));
    const displayTranslation = useMemo(() => showTranslation && IsDefined(textTranslation), [showTranslation, textTranslation]);
    const textToDisplay = useMemo(() => {
        let resultText = displayTranslation ? (textTranslation || '') : text;
        if (!showFullDescription &&
            IsDefined(resultText) &&
            isMaxLengthExceedsLimit(resultText)) {
            resultText = `${resultText.slice(0, DescriptionShortMaxLength)}...`;
        }
        return resultText || '';
    }, [
        displayTranslation,
        textTranslation,
        text,
        showFullDescription,
    ]);
    const canViewMore = useMemo(() => isMaxLengthExceedsLimit(textToDisplay), [textToDisplay]);
    const toggleTranslation = () => {
        if (!showTranslation) {
            translate();
        }
        setShowTranslation(!showTranslation);
    };
    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };
    return (_jsxs(Container, { isLoading: isLoading, children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 410, height: 50, children: _jsx(DescriptionText, { children: textToDisplay }) }), _jsx(WithSkeleton, { isLoading: isLoading, width: 213, height: 24, children: (canViewMore || canTranslate) &&
                    (_jsxs(DescriptionActions, { children: [canViewMore && (_jsx(ViewMoreToggle, { isViewMoreActive: showFullDescription, onClick: toggleDescription })), canViewMore &&
                                canTranslate && (_jsx(Divider, {})), canTranslate && (_jsx(ActiveTextButton, { "data-testid": 'seeTranslationToggle', onClick: toggleTranslation, children: _jsx(BodySemiBold, { lineHeight: '22px', children: displayTranslation ?
                                        t('profile.see_original') :
                                        t('profile.see_translation') }) }))] })) })] }));
};
export default ProfileDescription;
