import { SubscriptionStatus } from '@shared/api/subscription';
import { SUBSCRIPTION_PLANS } from './constants';
const mapSubscriptionPlans = (prices) => {
    const plans = [];
    prices.forEach((price) => {
        if (SUBSCRIPTION_PLANS[price.name]) {
            const key = price.name;
            plans.push((Object.assign(Object.assign({}, SUBSCRIPTION_PLANS[key]), { name: key, id: price.id, currency: price.currency, fee: parseFloat(price.unitAmount) || 0 })));
        }
    });
    return plans;
};
const defineCurrentSubscription = (plans) => {
    if (plans.length === 0) {
        return Object.assign(Object.assign({}, SUBSCRIPTION_PLANS.STARTER), { isSelected: true, isInList: false });
    }
    let currentPlan;
    plans.forEach((plan) => {
        if (SUBSCRIPTION_PLANS[plan.platformType] && plan.status === SubscriptionStatus.Active) {
            currentPlan = Object.assign(Object.assign({}, SUBSCRIPTION_PLANS[plan.platformType]), { id: plan.id, name: plan.platformType, isSelected: true, isInList: true });
        }
    });
    return currentPlan ? currentPlan : Object.assign(Object.assign({}, SUBSCRIPTION_PLANS.STARTER), { isSelected: true, isInList: false });
};
const getSubscriptionPlanByName = (name) => SUBSCRIPTION_PLANS[name];
export { defineCurrentSubscription, mapSubscriptionPlans, getSubscriptionPlanByName, };
